import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const StyledButton = styled.button`
  padding: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--primary);
  width: 134px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

const StyledRoundButton = styled.button`
  padding: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--primary);
  width: 40px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

const StyledLogo = styled.img`
width: 225px;
height: 87px;
@media screen and (min-width: 768px) and (max-width: 991px) {
    width: 187px;
    height: 72px;
}
@media screen and (max-width: 767px) {
    width: 150px;
    height: 58px;
`;

const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const StyledTextWrapperLarge = styled.span`
font-size: 24px;
font-weight: 600;
@media screen and (min-width: 768px) and (max-width: 991px) {
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  font-size: 18px;
`;

const StyledTextWrapperSmall = styled.span`
font-size: 16px;
font-weight: 400;
@media screen and (min-width: 768px) and (max-width: 991px) {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  font-size: 12px;
`;

const StyledSupplyWrapper = styled.span`
font-size: 50px;
@media screen and (min-width: 768px) and (max-width: 991px) {
  font-size: 40px;
}
@media screen and (max-width: 767px) {
  font-size: 30px;
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click the MINT button to become part of the Rumpel crew.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    EXPLORER_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE_LINK: ""
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = 110000 + mintAmount * 4000;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit);
    setFeedback(`Minting your Rumpel`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicSaleMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        //gasPrice: blockchain.gasPrice,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Error, please, try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Welcome to the Rumpel crew.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    console.log("getting data...");
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      console.log("data successfully fetched...");
    }
    else console.log("account or contract not set yet...");
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        // ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image="/config/images/background.png"
      >
        <a href="https://rumpelcrew.com">
          <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        </a>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 24,
              borderRadius: 24,
            }}
          >
            {/* SALE HAS ENDED */}
            <s.SpacerSmall />
            {1661205540 < Math.floor(Date.now() / 1000) || Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--tertiary)" }}
                >
                  <StyledTextWrapperLarge>
                    The sale has ended.
                  </StyledTextWrapperLarge>
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--tertiary)" }}
                >
                  <StyledTextWrapperLarge>
                    Try your luck on
                  </StyledTextWrapperLarge>
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  <StyledTextWrapperLarge>
                    OPENSEA.IO
                  </StyledTextWrapperLarge>
                </StyledLink>
              </>
            ) : (
              <>
                {/* INITIAL CONNECT */}
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--tertiary)",
                      }}
                    >
                      <StyledTextWrapperLarge>
                        Connect your wallet.
                      </StyledTextWrapperLarge>
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("initiating connect");
                        dispatch(connect());
                        console.log("initiating data");
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--tertiary)",
                          }}
                        >
                          <StyledTextWrapperLarge>
                            {blockchain.errorMsg}
                          </StyledTextWrapperLarge>
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  // CONNECT SUCCESSFULL - MINTING ONGOING
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--tertiary)" }}
                    >
                      <StyledTextWrapperLarge>
                        Ready to mint?
                      </StyledTextWrapperLarge>
                    </s.TextTitle>
                    <s.SpacerSmall />
                    {/* BUTTONS */}
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        // style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--tertiary)",
                        }}
                      >
                        <StyledTextWrapperLarge>
                          {mintAmount}
                        </StyledTextWrapperLarge>
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "IN PROGRESS" : "MINT"}
                      </StyledButton>
                    </s.Container>
                    <s.SpacerMedium />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--tertiary)",
                      }}
                    >
                      <StyledTextWrapperLarge>
                        {feedback}
                      </StyledTextWrapperLarge>
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--tertiary)" }}
                    >
                      <StyledTextWrapperLarge>
                        You can mint up to 10 in one transaction.
                      </StyledTextWrapperLarge>
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--tertiary)" }}
                    >
                      <StyledTextWrapperLarge>
                        {mintAmount} Rumpel(s) costs {(CONFIG.DISPLAY_COST * mintAmount).toFixed(3)}{" "}
                        {CONFIG.NETWORK.SYMBOL} excluding gas fees.
                      </StyledTextWrapperLarge>
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        fontSize: 50,
                        fontWeight: "bold",
                        color: "var(--tertiary)",
                      }}
                    >
                      <StyledSupplyWrapper>
                        {Number(data.totalSupply)} | {CONFIG.MAX_SUPPLY}
                      </StyledSupplyWrapper>
                    </s.TextTitle>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--tertiary)" }}
                    >
                      <StyledTextWrapperLarge>
                        Connected wallet: {blockchain.account}
                      </StyledTextWrapperLarge>
                    </s.TextTitle>

                  </>
                )}
              </>
            )}
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--tertiary)",
            }}
          >
            <StyledTextWrapperSmall>
              You can find Rumpels on {" "}
            </StyledTextWrapperSmall>
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              <StyledTextWrapperSmall>
                OPENSEA.IO
              </StyledTextWrapperSmall>
            </StyledLink>
          </s.TextDescription>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--tertiary)",
            }}
          >
            <StyledTextWrapperSmall>
              View on Etherscan {" "}
            </StyledTextWrapperSmall>
            <StyledTextWrapperSmall>
              <StyledLink target={"_blank"} href={CONFIG.EXPLORER_LINK}>
                {CONFIG.CONTRACT_ADDRESS}
              </StyledLink>
            </StyledTextWrapperSmall>
          </s.TextDescription>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--tertiary)",
            }}
          >
            <StyledTextWrapperSmall>
              Please make sure you are connected to the Ethereum Mainnet and double check the wallet address.
            </StyledTextWrapperSmall>
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
      </s.Container>
    </s.Screen>
  );
}

export default App;
